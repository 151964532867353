import React from "react"
import { SimpleTile } from "./SimpleTile"
import { Tile } from "./simple-tile-types"
import "./simple-tile.module.scss"

interface SimpleTileListProps {
  tiles: Tile[]
  hasAltBackgroundColor?: boolean
}

export const SimpleTileList = ({
  tiles,
  hasAltBackgroundColor = false,
}: SimpleTileListProps) => {
  const alternateBackground = hasAltBackgroundColor
    ? "alternate-background-color"
    : ""

  return (
    <section
      className={`tile-grid u-content-block-padding ${alternateBackground}`}
    >
      <div className="fr-container fr-container--large tile-grid__wrapper">
        {tiles.map(({ id, ...simpleTileProps }) => {
          const isSimpleTilesEmpty =
            !simpleTileProps ||
            (simpleTileProps && Object.keys(simpleTileProps).length === 0)

          if (isSimpleTilesEmpty) return

          return <SimpleTile key={id} tile={simpleTileProps} />
        })}
      </div>
    </section>
  )
}
