import React, { useCallback, useRef, useState } from "react"
import { graphql } from "gatsby"
import { useBandwidthConnection } from "hooks/useBandwidthConnection"
import { useMediaQuery } from "hooks/useMediaQuery"
import {
  BackgroundVideoContext,
  VideoState,
} from "./context/BackgroundVideoContext"
import { MP4VideoBackground } from "./MP4VideoBackground"
import "./background-video.module.scss"

interface BackgroundVideoProps {
  autoPlay?: boolean
  children?: React.ReactNode
  componentName: string
  hasBackgroundImage: boolean
  url?: string
}

export const BackgroundVideo = ({
  autoPlay = true,
  children,
  componentName,
  hasBackgroundImage,
  url,
}: BackgroundVideoProps) => {
  // This checks connection speed
  const isFastBandwidth = useBandwidthConnection()

  // This checks if the browser option to reduce motion is selected for accessibility reasons (e.g.,people with vestibular disorders and epilepsy)
  const prefersReducedMotion = useMediaQuery("(prefers-reduced-motion: reduce)")

  // Prevent Background Video Context to be added if there isn't a URL
  // OR if browser option to reduce motion is selected
  // Returns children because this component can be used as a wrapper
  if (!url || prefersReducedMotion) {
    return <>{children}</>
  }

  const videoRef = useRef<HTMLVideoElement>(null)

  const [playerState, setPlayerState] = useState<VideoState>(
    !!videoRef?.current?.paused ? "paused" : "playing"
  )

  const [playedAtLeastOnce, setPlayedAtLeastOnce] = useState(false)

  const handlePlayedAtLeastOnce = useCallback(() => {
    setPlayedAtLeastOnce(true)
  }, [])

  const handlePlayerState = useCallback((videoState: VideoState) => {
    setPlayerState(videoState)
  }, [])

  const isMP4Video = url.endsWith(".mp4")

  if (!isMP4Video) {
    console.error(
      "Please, provide an optimized video with file format as *.mp4. Video size must be 5MB maximum."
    )
    return <></>
  }

  const containerClassName = "fr-background-video__container"
  const modifierContainerClassName = `${containerClassName}--${componentName}`

  return (
    <BackgroundVideoContext.Provider
      value={{
        componentName,
        handlePlayedAtLeastOnce,
        handlePlayerState,
        playedAtLeastOnce,
        playerState,
        videoRef,
      }}
    >
      <>
        <div
          className={`${containerClassName} ${modifierContainerClassName} ${
            playedAtLeastOnce ? "played-once" : ""
          }`}
        >
          {isMP4Video && (
            <MP4VideoBackground
              autoPlay={autoPlay && isFastBandwidth}
              hasBackgroundImage={hasBackgroundImage}
              url={url}
            />
          )}
        </div>
        {children}
      </>
    </BackgroundVideoContext.Provider>
  )
}

export const strapiQuery = graphql`
  fragment StrapiVideoBackgroundFragment on STRAPI__COMPONENT_VIDEO_BACKGROUND_VIDEO {
    id
    strapi_component
    thumbnail {
      ...StrapiBaseImageFragment
      localFile {
        childImageSharp {
          gatsbyImageData(
            avifOptions: { lossless: true, speed: 10, quality: 30 }
            webpOptions: { quality: 40 }
            formats: [AUTO, AVIF, WEBP]
            placeholder: BLURRED
            breakpoints: [1024, 1366, 1920, 2560]
          )
        }
        publicURL
      }
    }
    video_title
    #video_upload {
    #  url
    #}
  }
`
