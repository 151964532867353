import React from "react"
import { graphql, PageProps } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo/seo"
import { GenericHero } from "../components/hero/GenericHero"
import { ContentBlocks } from "../components/content-blocks/ContentBlocks"
import { GlobalFooter } from "../components/global-footer/GlobalFooter"
import { SimpleTileList } from "../components/simple-tile/SimpleTileList"
import { TabbedHeroBanner } from "../components/hero/TabbedHeroBanner"
import { LocalBusinessSchema } from "components/schema/LocalBusinessSchema"
import { OfficeHours } from "components/office-hours/OfficeHours"
import { EMPTY_PAGE } from "assets/helper/EmptyPage"
import { Component } from "components/hero/_hooks/useHeroComponents"
import { HeroTab } from "components/hero/hero-tab"

interface GenericInteriorProps extends PageProps {
  pageContext: {
    slug: string
    breadcrumb: {
      crumbs: Record<string, string>[]
      location: string
    }
    contentUrlPathCodex: Record<string, string>
    linkedPagesUrlPathCodex: Record<string, string>
  }
  data: any
}

const GenericInterior: React.FC<GenericInteriorProps> = ({
  location,
  data,
  pageContext,
}) => {
  const { pathname, hash } = location
  const { contentUrlPathCodex, slug, linkedPagesUrlPathCodex } = pageContext

  const [genericPage] = data.allStrapiPage.nodes

  const {
    content,
    hero,
    hero_banner_components,
    meta_data,
    linked_pages,
    global_footer,
    noindex_this_page,
    is_pest_library_parent_page,
    is_gallery_parent_page,
    legacy_navigation_grid_alternate_background,
    alternating_content,
  } = genericPage ?? EMPTY_PAGE

  const isPestLibrary = is_pest_library_parent_page
  const isGallery = is_gallery_parent_page
  const hasAlternatingContent = alternating_content

  const noIndexPages = [
    "accessibility-statement",
    "terms-of-use",
    "terms-of-service",
    "privacy-policy",
  ]

  const noIndex =
    noIndexPages.some(page => page.includes(slug)) || noindex_this_page

  const isTabbedHero: boolean = hero_banner_components
    ? hero_banner_components.some(
        (heroComponent: Component | HeroTab) =>
          heroComponent.strapi_component === "banners.hero-tab"
      )
    : false

  return (
    <Layout
      contentUrlPathCodex={contentUrlPathCodex}
      location={slug}
      pathName={pathname}
      hash={hash}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
    >
      <SEO
        title={meta_data?.meta_title}
        description={meta_data?.meta_description}
        canonical={meta_data?.canonical_link}
        noIndex={noIndex}
        image={meta_data?.rich_data_image}
      />

      {hero && !isTabbedHero && <GenericHero banner={hero} />}

      {hero && isTabbedHero && (
        <TabbedHeroBanner banner={hero} components={hero_banner_components} />
      )}

      <OfficeHours slug={slug} />

      {content?.length > 0 && (
        <ContentBlocks
          strapiContent={content}
          hasAlternatingContentBlocks={hasAlternatingContent}
        />
      )}

      {(isPestLibrary || isGallery) && linked_pages.length > 0 && (
        <SimpleTileList
          tiles={linked_pages}
          hasAltBackgroundColor={legacy_navigation_grid_alternate_background}
        />
      )}

      {global_footer?.content?.length > 0 && (
        <GlobalFooter content={global_footer.content} />
      )}
    </Layout>
  )
}

export const getPage = graphql`
  query ($slug: String!) {
    allStrapiPage(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        slug
        noindex_this_page
        is_pest_library_parent_page
        is_gallery_parent_page
        is_service_page
        alternating_content
        meta_data {
          meta_title
          meta_description
          rich_data_link
          canonical_link
          rich_data_image {
            localFile {
              publicURL
            }
          }
        }
        hero {
          ...StrapiHeroBannerFragment
        }
        hero_banner_components {
          ...StrapiTabbedHeroBannerFragment
        }
        content {
          ...StrapiContentBlockFragment
          ...StrapiCouponBannerFragment
          ...StrapiCTABannerFragment
          ...StrapiFaqGroupBannerFragment
          ...StrapiFormFragment
          ...StrapiHighlightsGroupFragment
          ...StrapiHtmlEmbedFragment
          ...StrapiLogoSliderFragment
          ...StrapiMultiContentBlocksFragment
          ...StrapiFormFragment
          ...StrapiGalleryFragment
          ...StrapiNavigationTilesFragment
          ...StrapiServiceAreaGroupFragment
          ...StrapiServiceComparisonFragment
          ...StrapiServiceFragment
          ...StrapiTabbedContentBannerFragment
        }
        linked_pages {
          id
          title
          slug
          meta_data {
            rich_data_image {
              ...StrapiImageFragment
            }
            overlay_cta_text
          }
        }
        global_footer {
          ...StrapiGlobalFooterFragment
        }
      }
    }
  }
`
export default GenericInterior
