import React, { createContext } from "react"

export type VideoState = "playing" | "paused"

type BackgroundVideoContextData = {
  handlePlayedAtLeastOnce: () => void
  componentName: string
  playedAtLeastOnce: boolean
  videoRef: React.RefObject<HTMLVideoElement>
  handlePlayerState: (playerState: VideoState) => void
  playerState: VideoState
}

export const BackgroundVideoContext = createContext(
  {} as BackgroundVideoContextData
)
