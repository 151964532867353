import React from "react"
import "./office-hours.module.scss"
import { useGeneralSettings } from "hooks/useGeneralSettings"

interface OfficeHoursProps {
  slug: string
}

export const OfficeHours = ({
  slug,
}: OfficeHoursProps) => {
  const { formattedOffices } = useGeneralSettings()
  const mainOffice = formattedOffices[0]
  const simpleOfficeHours = mainOffice?.simpleOfficeHours
  const simpleServiceHours = mainOffice?.simpleServiceHours

  if (
    (simpleOfficeHours || simpleServiceHours) &&
    slug === "contact-us"
  )
    return (
      <div className="fr-office-hours u-content-block-padding">
        {simpleOfficeHours && (
          <span className="fr-office-hours__office">
            <strong>Office Hours: </strong> {simpleOfficeHours}
          </span>
        )}
        {simpleServiceHours && (
          <span className="fr-office-hours__service">
            <strong>Service Hours: </strong> {simpleServiceHours}
          </span>
        )}
      </div>
    )
  else return null
}


