import React, { HTMLAttributes } from "react"

interface SVGComponent extends HTMLAttributes<HTMLOrSVGElement> {}

export const PlayIcon = ({ ...rest }: SVGComponent) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.793"
    height="8.75"
    viewBox="0 0 8.793 8.75"
    {...rest}
  >
    <path
      id="Polygon_22"
      data-name="Polygon 22"
      d="M4.375,0,8.75,8.793H0Z"
      transform="translate(8.793) rotate(90)"
      fill="#fff"
    />
  </svg>
)
