import React, { useState } from "react"
import Slider from "react-slick"
import { useTabbedContainer } from "contexts/components/TabbedContainerContext"
import { BackgroundVideoPlayButton } from "components/background-video/BackgroundVideoPlayButton"
import { HeroContent } from "../HeroContent"
import { HeroTab } from "../hero-tab"
import { SampleNextArrow, SamplePrevArrow } from "./SliderArrow"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./tabbed-hero-content-slider.module.scss"

interface TabbedHeroContentSliderProps {
  hasAltBackgroundColor?: boolean
}

export const TabbedHeroContentSlider = ({}: // hasAltBackgroundColor = false,
TabbedHeroContentSliderProps) => {
  const [nav1, setNav1] = useState<Slider>()
  const [nav2, setNav2] = useState<Slider>()
  const { allContent } = useTabbedContainer<HeroTab>()

  const tabSettings = {
    dots: true,
    customPaging: function (i: number) {
      return (
        <button
          key={i}
          className="fr-tabbed-hero-content-slider__tab"
          type="button"
        >
          {i}
        </button>
      )
    },
    dotsClass: "slick-dots fr-tabbed-hero-content-slider__slick-dots",
    className: "fr-tabbed-hero-content-slider__tabs",
    infinite: true,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  const contentSettings = {
    dots: false,
    arrows: false,
    dotsClass: "slick-dots fr-tabbed-hero-content-slider__slick-dots",
    className: "fr-tabbed-hero-content-slider__content",
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
  }

  return (
    <>
      <div className="fr-tabbed-hero-content-slider__tabs-wrapper">
        <Slider
          asNavFor={nav2}
          {...tabSettings}
          ref={slider1 => slider1 && setNav1(slider1)}
        >
          {allContent.map(tab => {
            if (!tab) return
            const { id, tab_title } = tab
            return (
              <div key={id}>
                <div className="fr-tabbed-hero-content-slider__single-tab">
                  <strong>{tab_title}</strong>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
      <Slider
        asNavFor={nav1}
        {...contentSettings}
        ref={slider2 => slider2 && setNav2(slider2)}
      >
        {allContent.map(tab => {
          if (!tab) return null

          const {
            call_to_action_buttons,
            heading,
            id,
            tab_sub_heading_rich,
            video_background_url,
          } = tab

          const sub_heading = tab_sub_heading_rich?.data?.tab_sub_heading_rich

          return (
            <>
              <HeroContent
                baseClassName="fr-hero__banner-content"
                buttons={call_to_action_buttons}
                heading={heading}
                key={id}
                subHeading={sub_heading}
              />

              {video_background_url && <BackgroundVideoPlayButton />}
            </>
          )
        })}
      </Slider>
    </>
  )
}
