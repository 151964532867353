import React from "react"
import { useInView } from "react-intersection-observer"
import { SingleHighlight } from "../highlights-types"
import { Highlight } from "../Highlight"
import "./hero-highlights.module.scss"

interface HeroHighlightsProps {
  highlights: SingleHighlight[]
}

export const HeroHighlights = ({ highlights }: HeroHighlightsProps) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  })

  return (
    <div className="hero-highlights__wrapper" ref={ref}>
      {highlights.map(highlight => {
        const {
          animated_counter,
          caption,
          counter_end_value,
          counter_start_value,
          heading,
          id,
          image,
          special_character,
          svg_icon,
        } = highlight

        return (
          <Highlight
            baseClassName="hero-highlights"
            caption={caption}
            counterEndValue={counter_end_value}
            counterStartValue={counter_start_value}
            image={image}
            inView={inView}
            isAnimated={animated_counter}
            key={id}
            rightSvg={svg_icon}
            specialCharacter={special_character}
            title={heading}
          />
        )
      })}
    </div>
  )
}
