export const useBandwidthConnection = (): boolean => {
  const MIN_DOWNLOAD_BANDWIDTH = 1 // Slow 3G ~~ 0.4, Fast 3G ~~ 1.4
  let bandwidth = ""

  let downlink = MIN_DOWNLOAD_BANDWIDTH
  try {
    downlink = navigator.connection.downlink
  } catch (e) {
    console.log(`Unable to determine downlink`)
    bandwidth = "NOT SUPPORTED BY THIS BROWSER"
  }

  if (downlink >= MIN_DOWNLOAD_BANDWIDTH) {
    console.log(
      `Detected bandwidth (${downlink}Mbps) greater than threshold (${MIN_DOWNLOAD_BANDWIDTH}Mbps) - showing video`
    )
    bandwidth = "FAST"
  } else {
    console.log(
      `Detected bandwidth (${downlink}Mbps) less than threshold (${MIN_DOWNLOAD_BANDWIDTH}Mbps) - not showing video`
    )
    bandwidth = "SLOW"
  }

  switch (bandwidth) {
    case "FAST":
    case "NOT SUPPORTED BY THIS BROWSER":
      return true
    default:
      return false
  }
}
