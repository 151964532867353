export interface AppendLinkParams {
  id: string
  linkToAppend: string
  type?: string
  rel?: HTMLLinkElement["rel"]
  as?: HTMLLinkElement["as"]
}

export const appendLink = ({
  id,
  linkToAppend,
  type = "link",
  rel = "preload",
  as = "video",
}: AppendLinkParams) => {
  const WHERE = "head"
  const existingLink = document.getElementById(id)

  if (!existingLink) {
    const link = <HTMLLinkElement>document.createElement(type)
    link.id = id
    link.href = linkToAppend
    link.rel = rel
    link.as = as
    document[WHERE].appendChild(link)
  }
}
