import React, { ReactNode } from "react"
import { ButtonParser } from "components/button/ButtonParser"
import { Image } from "components/image/Image"
import { ParsedText } from "components/content/ParsedText"
import type { ButtonElements } from "_types/Button"
import type { StrapiImageItem } from "_types/AssetsImage"

interface HeroContentProps {
  baseClassName:
    | "fr-hero__banner-content-with-image"
    | "fr-hero__banner-content"
    | "ppc-hero__ppc-banner-content"
  buttons?: ButtonElements[] | null
  children?: ReactNode
  heading: string | null
  secondaryImage?: StrapiImageItem | null
  subHeading?: string | null
}

export const HeroContent = ({
  baseClassName,
  buttons,
  children,
  heading,
  secondaryImage,
  subHeading,
}: HeroContentProps) => {
  return (
    <div className={`${baseClassName}__inner fr-container`}>
      {heading && <h1 className={`${baseClassName}__heading`}>{heading}</h1>}
      {subHeading && <ParsedText content={subHeading} />}
      {buttons && buttons?.length > 0 && (
        <div className={`${baseClassName}__buttons`}>
          <ButtonParser
            buttons={buttons}
            navigationColorVariantSolid={"solid-secondary"}
            navigationColorVariantOutline={"outline-cta-ghost"}
            ctaColorVariantOutline={"outline-cta-ghost"}
            ctaColorVariantSolid={"solid-secondary"}
          />
        </div>
      )}
      {children}
      {secondaryImage && (
        <div className={`${baseClassName}__secondary-image`}>
          <Image
            alt={secondaryImage.alternativeText || ""}
            image={secondaryImage}
            loading="eager"
          />
        </div>
      )}
    </div>
  )
}
