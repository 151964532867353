import { CouponGroup } from "components/coupons/coupons"
import { FormBuilderProps } from "components/forms/formBuilder-types"
import { HighlightsGroup } from "components/highlights/highlights-types"
import { StrapiImageItem } from "_types/AssetsImage"

type HeroVideoComponent = {
  thumbnail: StrapiImageItem | null
  video_title: string | null
  video_upload: {
    url: string
  } | null
}

type HeroHighlightComponent = {
  highlights: HighlightsGroup[]
}

export type Component = {
  id: string
  strapi_component:
    | "content-block.coupon-banner"
    | "content-block.highlights-group"
    | "forms.form"
    | "video.background-video"
} & (
  | HeroHighlightComponent
  | FormBuilderProps
  | CouponGroup
  | HeroVideoComponent
)

export const useHeroComponents = (components?: Component[]) => {
  const highlights = (
    components?.filter(
      component =>
        component?.strapi_component === "content-block.highlights-group"
    )[0] as HeroHighlightComponent
  )?.highlights[0].highlights_collection

  const form = components?.filter(
    component => component?.strapi_component === "forms.form"
  )[0] as FormBuilderProps

  const coupon = (
    components?.filter(
      component => component?.strapi_component === "content-block.coupon-banner"
    )[0] as CouponGroup
  )?.coupon_list

  const videoBackground = components?.filter(
    component => component?.strapi_component === "video.background-video"
  )[0] as HeroVideoComponent

  const hasHighlights = !!highlights && highlights?.length > 0
  const hasForm = !!form && form?.form_field_groups.length > 0
  const hasCoupon = !!coupon
  const hasVideoBackgroundComponent = !!videoBackground

  return {
    coupon,
    form,
    hasCoupon,
    hasForm,
    hasHighlights,
    hasVideoBackgroundComponent,
    highlights,
    videoBackground,
  }
}
