import React from "react"
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import { useTabbedContainer } from "contexts/components/TabbedContainerContext"
import { useWindowDimensions } from "hooks/useWindowDimensions"
import { TabbedHeroContentSlider } from "./TabbedHeroContentSlider"
import { Image } from "components/image/Image"
import { Tabs } from "components/tabs/Tabs"
import type { HeroTab } from "../hero-tab"
import type { StrapiImageItem } from "_types/AssetsImage"

interface TabbedHeroBannerContentProps {
  contentClassName: string
  handleActiveTabContent: (activeTabContent: HeroTab) => JSX.Element
  handleGetHeroBgImage: (activeTabContent?: HeroTab) => StrapiImageItem | null
}

export const TabbedHeroBannerContent = ({
  contentClassName,
  handleActiveTabContent,
  handleGetHeroBgImage,
}: TabbedHeroBannerContentProps) => {
  const { activeItem } = useTabbedContainer<HeroTab>()
  const { imageBannerColorOverlay } = useColorAreaSettings()
  const { size } = useWindowDimensions()

  const activeBgImg = handleGetHeroBgImage(activeItem)

  const overrideBackground = imageBannerColorOverlay
    ? `${imageBannerColorOverlay}--backgroundOverlayBefore`
    : ""
  return (
    <section className={`fr-hero ${overrideBackground}`}>
      {activeBgImg && (
        <Image
          alt={activeBgImg.alternativeText ?? ""}
          className="fr-hero__background-image"
          image={activeBgImg}
        />
      )}
      <div className={`${contentClassName} fr-container`}>
        {size >= 769 && (
          <>
            <Tabs
              className="fr-hero-tabs-controller"
              nextIcon={IoMdArrowDropright}
              previousIcon={IoMdArrowDropleft}
            />
            {activeItem && handleActiveTabContent(activeItem)}
          </>
        )}
        {size > 0 && size <= 768 && <TabbedHeroContentSlider />}
      </div>
    </section>
  )
}
