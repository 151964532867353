import React, { useCallback } from "react"
import { graphql } from "gatsby"
import { HeroContent } from "../HeroContent"
import { TabbedContainer } from "components/tabs/TabbedContainer"
import { TabbedHeroBannerContent } from "./TabbedHeroBannerContent"
import type { Banner } from "../HeroBanner-types"
import type { HeroTab, HeroTabs } from "../hero-tab"
import { Component, useHeroComponents } from "../_hooks/useHeroComponents"
import { HeroHighlights } from "components/highlights/Hero/HeroHighlights"
import type { SingleHighlight } from "components/highlights/highlights-types"
import "../hero.module.scss"

interface TabbedHeroBannerProps {
  banner: Banner
  components: Array<HeroTab | Component>
}

export const TabbedHeroBanner = ({
  banner,
  components,
}: TabbedHeroBannerProps) => {
  const tabs = components?.filter(
    heroComponent => heroComponent.strapi_component === "banners.hero-tab"
  ) as HeroTabs

  const heroComponents = components?.filter(
    heroComponent => heroComponent.strapi_component !== "banners.hero-tab"
  ) as Component[]

  const { hasHighlights, highlights } = useHeroComponents(heroComponents)

  const { background_image, secondary_image } = banner

  const hasSecondaryImage =
    tabs.some(tab => tab.secondary_image_override !== null) ||
    secondary_image !== null

  const contentClassName = hasSecondaryImage
    ? "fr-hero__banner-content-with-image"
    : "fr-hero__banner-content"

  const getActiveTabContent = useCallback(
    (activeTabContent: HeroTab) => {
      const {
        call_to_action_buttons,
        heading,
        secondary_image_override,
        tab_sub_heading_rich,
        highlights_override,
      } = activeTabContent

      const sub_heading = tab_sub_heading_rich?.data?.tab_sub_heading_rich

      const hasSecondaryImageOverride =
        !!secondary_image_override && !!secondary_image_override[0]
      const hasDefaultSecondaryImage = !!secondary_image
      const hasSecondaryImage =
        hasDefaultSecondaryImage || hasSecondaryImageOverride

      const hasHighlightsOverride =
        !!highlights_override &&
        highlights_override.highlights.length > 0 &&
        highlights_override.highlights[0] &&
        !!highlights_override.highlights[0].highlights_collection &&
        highlights_override.highlights[0].highlights_collection.length > 0

      const highlightsOverride = hasHighlightsOverride
        ? (highlights_override.highlights[0]
            .highlights_collection as SingleHighlight[])
        : ([] as SingleHighlight[])

      return (
        <HeroContent
          baseClassName={contentClassName}
          buttons={call_to_action_buttons}
          heading={heading}
          secondaryImage={
            hasSecondaryImageOverride
              ? secondary_image_override[0]
              : secondary_image
          }
          subHeading={sub_heading}
        >
          {!hasSecondaryImage && (hasHighlights || hasHighlightsOverride) && (
            <div className="hero-banner-content__highlights">
              <HeroHighlights
                highlights={
                  hasHighlightsOverride
                    ? highlightsOverride
                    : (highlights as SingleHighlight[])
                }
              />
            </div>
          )}
        </HeroContent>
      )
    },
    [tabs, secondary_image, highlights, hasHighlights]
  )

  const handleGetHeroBgImage = useCallback(
    (activeTabContent?: HeroTab) => {
      if (!activeTabContent) return null

      const { background_image_override } = activeTabContent

      if (background_image_override && background_image_override.length > 0) {
        return background_image_override[0]
      }

      return background_image
    },
    [background_image]
  )

  return (
    <TabbedContainer allContent={tabs}>
      <TabbedHeroBannerContent
        contentClassName={contentClassName}
        handleActiveTabContent={getActiveTabContent}
        handleGetHeroBgImage={handleGetHeroBgImage}
      />
    </TabbedContainer>
  )
}

export const strapiQuery = graphql`
  fragment StrapiTabbedHeroBannerFragment on STRAPI__COMPONENT_BANNERS_HERO_TAB {
    strapi_component
    background_image_override {
      ...StrapiBaseImageFragment
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData(
            avifOptions: { lossless: true, speed: 10, quality: 50 }
            webpOptions: { quality: 60 }
            formats: [AUTO, AVIF, WEBP]
            placeholder: BLURRED
            breakpoints: [1024, 1366, 1920, 2560]
          )
        }
      }
    }
    call_to_action_buttons {
      ...StrapiButtonFragment
    }
    eyebrow_text
    heading
    id
    secondary_image_override {
      ...StrapiBaseImageFragment
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData(
            avifOptions: { lossless: true, quality: 70, speed: 10 }
            webpOptions: { quality: 80 }
            formats: [AUTO, AVIF, WEBP]
            placeholder: NONE
            layout: FIXED
          )
        }
      }
    }
    highlights_override {
      ...StrapiHighlightsGroupFragment
    }
    tab_sub_heading_rich {
      data {
        tab_sub_heading_rich
      }
    }
    tab_title
    tagline
  }
`
