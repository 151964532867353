import React from "react"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import { BackgroundVideo } from "components/background-video"
import { BackgroundVideoPlayButton } from "components/background-video/BackgroundVideoPlayButton"
import { HeroContent } from "./HeroContent"
import type { Banner } from "./HeroBanner-types"
import { Image } from "components/image/Image"
import { Component, useHeroComponents } from "./_hooks/useHeroComponents"
import "./hero.module.scss"
import "./hero-background-video.module.scss"

interface HeroProps {
  banner: Banner
  components?: Component[]
}

export const GenericHero = ({ banner, components }: HeroProps) => {
  const {
    background_image,
    heading,
    hero_buttons,
    sub_heading_rich,
    video_background_url,
  } = banner

  const { hasVideoBackgroundComponent, videoBackground } =
    useHeroComponents(components)

  const showPlayButton =
    (hasVideoBackgroundComponent && videoBackground?.video_upload?.url) ||
    !!video_background_url

  const { textBannerBackgroundColor, imageBannerColorOverlay } =
    useColorAreaSettings()

  const isTextOnly = background_image ? false : true

  const bannerColorOverride = textBannerBackgroundColor
    ? `${textBannerBackgroundColor}--backgroundColor`
    : ``
  const overrideBackground = imageBannerColorOverlay
    ? `${imageBannerColorOverlay}--backgroundOverlayBefore`
    : ""

  return (
    <section
      className={`${
        isTextOnly
          ? `fr-hero--default-background-color ${bannerColorOverride}`
          : "fr-hero--medium-height"
      } ${overrideBackground}`}
    >
      {background_image && (
        <Image
          alt={background_image.alternativeText || ""}
          className="fr-hero__background-image"
          image={background_image}
        />
      )}
      <BackgroundVideo
        componentName="fr-generic-hero"
        hasBackgroundImage={!!background_image?.localFile?.publicURL}
        url={
          hasVideoBackgroundComponent
            ? videoBackground?.video_upload?.url
            : video_background_url
        }
      >
        <div
          className={
            isTextOnly
              ? `fr-hero__banner-content--padding-large fr-container`
              : `fr-hero__banner-content fr-container`
          }
        >
          <HeroContent
            baseClassName="fr-hero__banner-content"
            buttons={hero_buttons}
            heading={heading}
            subHeading={sub_heading_rich?.data?.sub_heading_rich}
          >
            {showPlayButton && <BackgroundVideoPlayButton />}
          </HeroContent>
        </div>
      </BackgroundVideo>
    </section>
  )
}
