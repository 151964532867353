import React, { useEffect } from "react"
import { appendLink, AppendLinkParams } from "assets/helper/appendLink"
import { useLayout } from "contexts/components/LayoutContext"
import { useBackgroundVideo } from "./hooks/useBackgroundVideo"
import "./background-video.module.scss"

interface MP4VideoBackgroundProps {
  autoPlay: boolean
  hasBackgroundImage: boolean
  url: string
}

export const MP4VideoBackground = ({
  autoPlay,
  hasBackgroundImage,
  url,
}: MP4VideoBackgroundProps) => {
  const { slug } = useLayout()
  const {
    componentName,
    handlePlayedAtLeastOnce,
    playedAtLeastOnce,
    videoRef,
  } = useBackgroundVideo()

  useEffect(() => {
    const videoLink: AppendLinkParams = {
      id: `${componentName}-background-video_at_${slug}`,
      linkToAppend: url,
    }

    appendLink(videoLink)
  }, [])

  useEffect(() => {
    if (!videoRef.current) return
    if (!autoPlay) return
    if (playedAtLeastOnce) return

    videoRef.current?.play()

    // Smoothly add background transition behind video
    let mounted = true

    setTimeout(() => {
      if (mounted) {
        handlePlayedAtLeastOnce()
      }
    }, 100)

    return () => {
      mounted = false
    }
  }, [videoRef, playedAtLeastOnce, autoPlay])

  const playerState = videoRef.current?.paused ? "paused" : "playing"

  return (
    <video
      ref={videoRef}
      className={`fr-background-video__video ${playerState}`}
      preload={hasBackgroundImage ? "none" : "metadata"}
      src={url}
      aria-label="Background Video Animation"
      muted
      loop
      playsInline
      disableRemotePlayback
    ></video>
  )
}
