import React from "react"
import { Link, graphql } from "gatsby"
import { VscTriangleRight } from "react-icons/vsc"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import { Tile } from "./simple-tile-types"
import { Image } from "components/image/Image"
import { useLayout } from "contexts/components/LayoutContext"
import { getRelativePathFromCodex } from "components/button/utils/getRelativePathFromCodex"
import "./simple-tile.module.scss"

interface SimpleTileProps {
  tile: Tile
}

export const SimpleTile = ({ tile }: SimpleTileProps) => {
  const { title, slug, meta_data } = tile

  const { navTileColorOverlay } = useColorAreaSettings()
  const overrideBackground = navTileColorOverlay
    ? `${navTileColorOverlay}--backgroundColorGradient`
    : ""

  const { linkedPagesUrlPathCodex } = useLayout()

  const relativePath = getRelativePathFromCodex({
    codex: linkedPagesUrlPathCodex,
    path: slug,
    codexKeyStartsWithForwardSlash: false,
  })

  return (
    <Link to={relativePath} className="tile">
      {meta_data?.rich_data_image && (
        <div className="tile__body">
          <Image
            image={meta_data?.rich_data_image}
            alt={meta_data?.rich_data_image?.alternativeText ?? ""}
            className="tile__image"
          />

          <div className={`tile__overlay ${overrideBackground}`}></div>
          <p className="tile__overlay-cta">
            {meta_data?.overlay_cta_text || "Learn More"}
            <VscTriangleRight className="tile__overlay-triangle" />
          </p>
        </div>
      )}
      <div className="tile__footer-wrapper">
        <h3 className="tile__footer">{title}</h3>
      </div>
    </Link>
  )
}

export const query = graphql`
  fragment SimpleTileFragment on kontent_item_legacy_pest_library {
    id
    elements {
      pages {
        value {
          ... on kontent_item_page {
            id
            elements {
              slug {
                value
              }
              slug_generator {
                value
              }
              seo_metadata__rich_data_image {
                value {
                  url
                  width
                  height
                }
              }
              seo_metadata__overlay_cta_text {
                value
              }
            }
          }
        }
      }
    }
  }
`
