import React, { useContext } from "react"
import { BackgroundVideoContext } from "../context/BackgroundVideoContext"

export function useBackgroundVideo() {
  const context = useContext(BackgroundVideoContext)

  if (!context) {
    throw new Error(
      "useBackgroundVideo must be used within BackgroundVideoContext"
    )
  }

  return context
}
